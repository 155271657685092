import React from 'react'
import { Link } from 'gatsby'

export default function NotFound() {
  return (
    <div className="text-center">
      <h1 className="text-3xl font-medium">404</h1>
      <h2>hmm looks like you have hit a dead end</h2>
      <p>Try any of the links below</p>
      <Link to="/">Home</Link>
    </div>
  )
}
